export default class FAQTabs {
    constructor() {

        //Setting tabs and content to active on click
        const nav = document.querySelectorAll('.faq-tab');
        const tabs = document.querySelectorAll('.accordion-single-wrapper');

        const closeAll = () => {
            nav.forEach(item => {
                item.classList.remove('is-active');
                item.setAttribute('aria-selected', 'false');
            })

            tabs.forEach(item => {
                item.classList.remove('is-active');
            })
        }

        nav.forEach(item => {
            item.addEventListener('click', () => {
                closeAll();
                
                item.classList.add('is-active');
                item.setAttribute('aria-selected', 'true');

                const panel = document.querySelectorAll('.accordion-single-wrapper');

                if (!panel) {
                    console.warn('Invalid panel id', item.getAttribute('aria-control'));
                    return;
                }

                panel.forEach(element => {
                    if(item.getAttribute('tab-index') === element.getAttribute('tab-index')) {
                        element.classList.add('is-active');
                    }
                })

            });
        });

        this.openTreatmentAccordion();
        
        // Accordion open content and close
        const accordionItems = document.querySelectorAll(".accordion-single--title");

        accordionItems.forEach(item =>
        item.addEventListener("click", () => {
            const isItemOpen = item.classList.contains("open");
            accordionItems.forEach(item => item.classList.remove("open"));
            if (!isItemOpen) {
            item.classList.toggle("open");
            }
        })
    );
    }

    //Set access tab active on page load
    openTreatmentAccordion() {

        const treatmentTab = document.querySelectorAll('.accordion-treatment');

        if(treatmentTab.length > 0) {
            treatmentTab.forEach(item => {
                item.classList.add('is-active');
                item.setAttribute('aria-selected', 'true');

                const treatmentAccordion = document.querySelectorAll(".accordion-treatment");

                if (treatmentAccordion) {
                    treatmentAccordion.forEach(item => {
                        item.classList.add('is-active');
                    })
                }

            })
        } else {
            return;
        }

    };
}
