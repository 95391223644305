import Swiper from "swiper";
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import "swiper/css/effect-coverflow";

export default class TestimonialSwiper {
    constructor() {
        const swiper = new Swiper(".testimonials-page-wrapper", {
            
            grabCursor: true,
            // centeredSlides: true,
            slidesPerView: 1,
            loop: true,
            autoplay: {
                delay: 4000,
            },
            
            modules: [ Autoplay ],
        });
    }
}


