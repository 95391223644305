import '@styles/main.scss';
import FAQTabs from './components/faq-tabs';
import Accordion from './components/accordion';
import Menu from './components/menu';
import MenuFixed from './components/menu-fixed';
import TestimonialSwiper from './components/testimonials-swiper';

class App {
    constructor() {
        new FAQTabs();
        new Accordion();
        new Menu();
        new MenuFixed();
        new TestimonialSwiper();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});

