export default class MenuFixed {
    constructor() {
        const header = document.getElementById('site-header');
        const toggle = document.getElementById('hamburger');

        window.addEventListener('scroll', () => {
            const y = window.scrollY;
            if (y > 100 && !header.classList.contains('is-fixed')) {
                header.classList.add('is-fixed');
                toggle?.classList.add('is-fixed');
            } else if (y < 100) {
                header.classList.remove('is-fixed');
                toggle?.classList.remove('is-fixed');
            }
        });

    }
}