export default class Accordion {
    constructor() {
        
        // Accordion open content and close
        const accordionItems = document.querySelectorAll(".accordion-widget--title");

        accordionItems.forEach(item =>
        item.addEventListener("click", () => {
            const isItemOpen = item.classList.contains("open");
            accordionItems.forEach(item => item.classList.remove("open"));
            if (!isItemOpen) {
            item.classList.toggle("open");
            }
        })
    );
    }
}
