export default class Menu {
    constructor() {
        const hamburger = document.getElementById('hamburger');

        if (!hamburger) {
            return;
        }
    
        const menu = document.getElementById('menu-primary-menu'),
            overlay = document.getElementById('menu-overlay'),
            submenu = document.querySelectorAll('.sub-menu'),
            subParent = document.querySelectorAll('.menu-item-has-children')
    
        const close = () => {
            menu?.classList.remove('is-active');
            hamburger.classList.remove('is-active');
            overlay?.classList.remove('is-active');
            document.documentElement.classList.remove('has-menu-open');
        }
    
        hamburger.addEventListener('click', ()=> {
            menu?.classList.toggle('is-active');
            hamburger.classList.toggle('is-active');
            overlay?.classList.toggle('is-active');
            document.documentElement.classList.toggle('has-menu-open');
        });

        subParent.forEach(parent => {
            parent.addEventListener('click', (event) => {
                const clickedParent = event.currentTarget;

                if(!clickedParent) return;
                
                const clickedSubMenu = clickedParent.querySelector('.sub-menu');
                
                submenu.forEach(element => {
                    if (element !== clickedSubMenu) {
                        element.classList.remove('is-active');
                    }
                });

                clickedSubMenu.classList.toggle('is-active');
            });
        });
    
        overlay.addEventListener('click', () => close());

    }
    
}
